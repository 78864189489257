<template>
  <div>
    <TablePage
      ref="tablePage"
      class="tablepage"
      v-model="options"
      @handleEvent="handleEvent"
    >
    </TablePage>
  </div>
</template>

<script>
import TablePage from "@/components/tablePage";
import { listlevel } from "@/api/vip/base/level"; //会员的级别
import { levelShopList, levelSaveOrUpdate } from "@/api/vip/base/levelShop"; //级别门店
export default {
  name: "LevelShop",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        mutiSelect: true, // 多选
        // selectable: row => row.produceStoreNo != '0000',
        loading: false,
        uuid: "4e1e0011-1485-6d88-8d84-d482390a4692",
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        tree: {
          title: "会员级别",
          defaultBody: {
            pageNum: 1,
            pageSize: 99999,
          },
          dataKey: "rows",
          getListApi: listlevel,
          label: "vipLevelName",
          value: "vipLevelId",
        },
        getListApi: levelShopList,
        body: {},
        defaultBody: {},
        title: "级别适用门店",
        listNo: true, // 序号
        setCurrentPageCheck: () =>
          this.options.list?.filter?.((item) => item.isSelected) || [],
        rowKey: "shopId",
        check: [],
        //搜索
        search: [
          {
            type: "filters",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编码" },
              { filter: "shopNames", label: "门店名称" },
            ],
          },
          {
            type: "button",
            tip: "查询",
            btnType: "primary",
            click: "search",
            isRestore: true,
          },
        ],
        //按钮
        buttons: [
          {
            click: "save",
            right: true,
            label: "保存",
            type: "primary",
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "shopNo",
            label: "门店编码",
            minWidth: 180,
          },
          {
            prop: "shopName",
            label: "门店名称",
            minWidth: 180,
          },
          {
            prop: "shopTypeName",
            label: "门店类型",
            minWidth: 100,
          },
          {
            prop: "useArea",
            label: "门店面积",
            minWidth: 100,
          },
          {
            prop: "linkEmployeeName",
            label: "联系人",
            minWidth: 120,
          },
          {
            prop: "linkEmployeeTel",
            label: "联系电话",
            minWidth: 120,
          },
          {
            prop: "totalAddress",
            label: "门店地址",
            minWidth: 120,
          },
          {
            prop: "zjm",
            label: "助记码",
            minWidth: 120,
          },
          {
            prop: "sortNo",
            label: "排序",
            minWidth: 120,
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 120,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "save":
          let checkDataIds = this.options.list.map((v) => {
            if (v.isSelected) {
              return v.shopId;
            }
          });
          let checkIds = this.options.check.map((v) => v.shopId);
          const adds = checkIds
            .filter((shopId) => !checkDataIds.includes(shopId))
            .filter((ite) => typeof ite !== "undefined");
          const dels = checkDataIds
            .filter((shopId) => !checkIds.includes(shopId))
            .filter((ite) => typeof ite !== "undefined");
          await levelSaveOrUpdate({
            addShopIds: adds,
            deleteShopIds: dels,
            vipLevelId: this.options?.tree?.body?.vipLevelId,
          });
          await this.$refs.tablePage.getList();
          this.$message.success("保存成功！");
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
