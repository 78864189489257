<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
    <!-- 弹窗 -->
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import Dialog from "@/components/Dialog";
import {
  goodsList,
  goodsRemove,
  changeGoodsDetail,
} from "@/api/vip/base/scoreExchangeRule"; //会员积分兑换商品
import {
  couponListDetail,
  couponList,
  couponRemove,
} from "@/api/vip/base/scoreExchangeRule"; //会员积分兑换优惠券
export default {
  name: "scoreExchangeRule",
  components: { TablePage, Dialog },
  data() {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: false,
        uuid: "3e338ac3-c6a7-eabd-eed7-80026aaa7d61",
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "会员积分规则列表",
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "billId",
        tabColumnType: "goods",
        //表格
        tabsColumns: [
          {
            title: "积分兑换商品",
            type: "goods",
            getListApi: goodsList,
            rowKey: "goodsId",
            //搜索
            search: [
              {
                type: "filters",
                tip: "商品编码/商品名称",
                model: "",
                filters: [
                  { filter: "query", label: "全部" },
                  { filter: "goodsNos", label: "商品编码" },
                  { filter: "goodsNames", label: "商品名称" },
                ],
              },
              {
                type: "button",
                tip: "查询",
                btnType: "primary",
                click: "search",
              },
            ],
            //按钮
            buttons: [
              {
                click: "add-goods",
                label: "新增",
                icon: "el-icon-plus",
                type: "primary",
              },
              {
                click: "del-goods",
                label: "删除",
                type: "danger",
                icon: "el-icon-delete",
                alertText: "确认要删除选中方案吗？",
              },
              {
                click: "refresh",
                right: true,
                label: "刷新",
                icon: "el-icon-refresh",
                type: "",
              },
            ],
            columns: [
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
                type: "link",
                click: "update-goods",
                align: "center",
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "equalScore",
                label: "等价积分",
                minWidth: 150,
              },
              {
                prop: "addBuyPrice",
                label: "加购金额",
                minWidth: 150,
              },
              {
                prop: "goodsEmitQty",
                label: "总发放数量",
                minWidth: 150,
              },
              {
                prop: "goodsEveryLimitQty",
                label: "每人限兑换数量",
                minWidth: 150,
              },
              {
                prop: "goodsSumScoreQty",
                label: "已兑换数量",
                minWidth: 150,
              },
              {
                prop: "allowOnline",
                label: "适用渠道",
                minWidth: 100,
              },
              {
                prop: "startTime",
                label: "生效时间",
                minWidth: 150,
              },
              {
                prop: "endTime",
                label: "失效时间",
                minWidth: 100,
              },

              {
                prop: "updateBy",
                label: "修改人",
                minWidth: 100,
              },
              {
                prop: "updateTime",
                label: "修改时间",
                minWidth: 100,
              },
              {
                prop: "createBy",
                label: "创建人",
                minWidth: 100,
              },
              {
                prop: "createTime",
                label: "创建时间",
                minWidth: 150,
              },
            ],
            summary: ["equalScore", "addBuyPrice", "goodsEmitQty", "goodsSumScoreQty"],
          },
          {
            title: "积分兑换优惠券",
            type: "coupon",
            getListApi: couponList,
            rowKey: "couponCaseId",
            //搜索
            search: [
              {
                type: "filters",
                tip: "优惠券编号/优惠券名称",
                model: "",
                filters: [
                  { filter: "query", label: "全部" },
                  { filter: "couponNos", label: "优惠券编号" },
                  { filter: "couponNames", label: "优惠券名称" },
                ],
              },
              {
                type: "button",
                tip: "查询",
                btnType: "primary",
                click: "search",
              },
            ],
            //按钮
            buttons: [
              {
                click: "add-coupon",
                label: "新增",
                icon: "el-icon-plus",
                type: "primary",
              },
              {
                click: "del-coupon",
                label: "删除",
                type: "danger",
                icon: "el-icon-delete",
                alertText: "确认要删除选中方案吗？",
              },
              {
                click: "refresh",
                right: true,
                label: "刷新",
                icon: "el-icon-refresh",
                type: "",
              },
            ],
            columns: [
              {
                prop: "equalScore",
                label: "优惠券编号",
                minWidth: 150,
                type: "link",
                click: "update-coupon",
                align: "center",
              },
              {
                prop: "couponCaseName",
                label: "优惠券名称",
                minWidth: 80,
              },
              {
                prop: "equalScore",
                label: "等价积分",
                minWidth: 80,
              },
              {
                prop: "addBuyPrice",
                label: "加购金额",
                minWidth: 80,
              },
              {
                prop: "couponEmitQty",
                label: "总发放数量",
                minWidth: 80,
              },
              {
                prop: "couponEveryLimitQty",
                label: "每人限兑换数量",
                minWidth: 80,
              },
              {
                prop: "couponSumScoreQty",
                label: "已兑换数量",
                minWidth: 80,
              },
              {
                prop: "allowOnline",
                label: "适用渠道",
                minWidth: 80,
              },
              {
                prop: "startTime",
                label: "生效时间",
                minWidth: 150,
              },
              {
                prop: "endTime",
                label: "失效时间",
                minWidth: 100,
              },
              {
                prop: "updateBy",
                label: "修改人",
                minWidth: 80,
              },
              {
                prop: "updateTime",
                label: "修改时间",
                minWidth: 80,
              },
              {
                prop: "createBy",
                label: "创建人",
                minWidth: 80,
              },
              {
                prop: "createTime",
                label: "创建时间",
                minWidth: 80,
              },
            ],
            summary: ["equalScore", "addBuyPrice", "couponEmitQty", "couponSumScoreQty"],
          },
        ],
      },
      //弹窗配置
      dialogOptions: {
        curType: "", //当前类型(门店/会员/商品/类别/品牌)
        title: "选择兑换商品",
        width: 600,
        show: false,
        type: "ScoreExchangeGoodsRule",
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "add-goods": //新增
          //弹窗配置
          this.dialogOptions = {
            title: "选择兑换商品",
            width: 600,
            show: true,
            click: "add",
            type: "ScoreExchangeGoodsRule",
          };
          break;
        case "update-goods":
          const { scoreGoodsId } = row;
          try {
            const { data } = await changeGoodsDetail(scoreGoodsId);
            this.dialogOptions = {
              title: "选择兑换商品",
              width: 600,
              show: true,
              click: "update",
              type: "ScoreExchangeGoodsRule",
              formData: { ...data, datetime: [data.startTime, data.endTime] },
            };
          } catch (err) {}
          break;
        case "del-goods":
          try {
            await goodsRemove(this.options.check.map((item) => item.scoreGoodsId));
            this.$message.success("删除成功");
            this.$refs.tablePage.getList();
          } catch (err) {}
          break;
        case "add-coupon":
          //弹窗配置
          this.dialogOptions = {
            title: "选择兑换优惠券",
            width: 600,
            show: true,
            click: "add",
            type: "ScoreExchangeCouponRule",
          };
          break;
        case "update-coupon":
          const { scoreCouponId } = row;
          try {
            const { data } = await couponListDetail(scoreCouponId);
            this.dialogOptions = {
              title: "选择兑换商品",
              width: 600,
              show: true,
              click: "update",
              type: "ScoreExchangeCouponRule",
              formData: { ...data, datetime: [data.startTime, data.endTime] },
            };
          } catch (err) {}
          break;
        case "del-coupon":
          try {
            await couponRemove(this.options.check.map((item) => item.scoreCouponId));
            this.$message.success("删除成功");
            this.$refs.tablePage.getList();
          } catch (err) {}
          break;
        case "dialogChange":
          this.$refs.tablePage.getList();
          break;
        default:
          break;
      }
    },
  },
};
</script>
