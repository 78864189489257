<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>

<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  vipDiscountRuleList,
  vipDiscountRuleUpdateStatus,
  vipDiscountRuleIsStop,
  vipDiscountRuleCopy,
} from "@/api/vip/base/discountRule";

export default {
  name: "discountRule",
  components: { TablePage },
  dicts: ["bill_status"],
  data() {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: false,
        uuid: "9c4efafc-2cdb-d9bb-c072-fa13804b54d7",
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "会员折扣规则列表",
        getListApi: vipDiscountRuleList,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "billId",
        //搜索
        search: [
          {
            label: "创建时间",
            type: "datetimerange",
            model: "",
            noReset: true,
            clearable: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "input",
            label: "方案编号",
            model: "",
            filter: "billNo",
            seniorSearch: true,
          },
          {
            type: "input",
            label: "方案名称",
            model: "",
            filter: "billRuleName",
            seniorSearch: true,
          },
          this.$select({
            key: "bill_status",
            option: {
              filter: "billStatuss",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "isStopState",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //按钮
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "audit",
            label: "审核",
            alertText: "确认要审核选中方案吗？",
            type: "success",
            icon: "el-icon-document",
          },
          {
            click: "start",
            label: "启用",
            alertText: "确认要启用选中方案吗？",
            btnType: "dropdown",
            other: [
              {
                click: "stop",
                label: "禁用",
                alertText: "确认要禁用选中方案吗？",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "copy",
            label: "复制",
            type: "success",
            icon: "el-icon-document-copy",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "billNo",
            label: "方案编号",
            type: "link",
            click: "update",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "billRuleName",
            label: "方案名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "billStatusName",
            label: "单据状态",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "isStop",
            label: "启用状态",
            minWidth: 120,
            align: "center",
            formatter: (propValue) => {
              return propValue ? "禁用" : "启用";
            },
          },
          {
            prop: "shopRangeTypeName",
            label: "适用门店范围",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "auditTime",
            label: "审核日期",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "updateTime",
            label: "修改日期",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "createTime",
            label: "创建日期",
            minWidth: 120,
            align: "center",
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      let billIds = [];
      switch (type) {
        case "add":
          this.$router.push({
            name: "discountRuleDetail",
          });
          break;
        case "update":
          this.$router.push({
            name: "discountRuleDetail",
            query: {
              billId: row.billId,
              type: "Update",
            },
          });
          break;
        case "audit":
          this.options.check.forEach((item) => {
            if (item.billStatus == "0") billIds.push(item.billId);
          });
          if (billIds.length >= 1) {
            try {
              await vipDiscountRuleUpdateStatus({
                billIds,
                billStatus: 2,
              });
              this.$message.success("审核成功");
              this.$refs.tablePage.getList();
            } catch (err) {}
          } else {
            this.$message.success("请选择未审核的方案");
          }
          break;
        case "reverseAudit":
          this.options.check.forEach((item) => {
            if (item.billStatus == "2") billIds.push(item.billId);
          });
          if (billIds.length >= 1) {
            try {
              await vipDiscountRuleUpdateStatus({
                billIds,
                billStatus: 0,
              });
              this.$message.success("反审核成功");
              this.$refs.tablePage.getList();
            } catch (err) {}
          } else {
            this.$message.success("请选择已审核的方案");
          }
          break;
        case "start":
          this.options.check.forEach((item) => {
            if (item.billStatus == "2" && item.isStop) billIds.push(item.billId);
          });
          if (billIds.length >= 1) {
            try {
              await vipDiscountRuleIsStop({
                billIds,
                isStop: 0,
              });
              this.$message.success("启用成功");
              this.$refs.tablePage.getList();
            } catch (err) {}
          } else {
            this.$message.success("请选择已审核未启用的方案");
          }
          break;
        case "stop":
          this.options.check.forEach((item) => {
            if (item.billStatus == "2" && !item.isStop) billIds.push(item.billId);
          });
          if (billIds.length >= 1) {
            try {
              await vipDiscountRuleIsStop({
                billIds,
                isStop: 1,
              });
              this.$message.success("禁用成功");
              this.$refs.tablePage.getList();
            } catch (err) {}
          } else {
            this.$message.success("请选择已审核已启用的方案");
          }
          break;
        case "copy":
          billIds = this.options.check.map((item) => item.billId);
          try {
            await vipDiscountRuleCopy(billIds);
            this.$message.success("复制成功");
            this.$refs.tablePage.getList();
          } catch (err) {}
          break;
        case "del":
          this.options.check.forEach((item) => {
            if (item.billStatus == "0") billIds.push(item.billId);
          });
          if (billIds.length >= 1) {
            try {
              await vipDiscountRuleUpdateStatus({
                billIds,
                billStatus: 3,
              });
              this.$message.success("删除成功");
              this.$refs.tablePage.getList();
            } catch (err) {}
          } else {
            this.$message.success("请选择未审核的方案");
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
